<template>
  <div class="header">
    <div class="logo">
      <img class="logo-img" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024020565da69215320697a574661.png" alt="">
      <!-- <img class="slogan" src="https://image.xiaoxxx.cn/test/urlGather/20240429fd6dd5c6545175d0529897.png" alt=""> -->
      <span class="slogan">营销互动技术源头供应商</span>
    </div>
    
    <div class="nav">
      <div :class="active == 'Home' ? 'active' : ''" @click="routerTo('Home')">
        首页
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div :class="active == 'Case' ? 'active' : ''" @click="routerTo('Case')">
        精选案例
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div :class="active == 'News' ? 'active' : ''" @click="routerTo('News')">
        新闻资讯
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div :class="active == 'About' ? 'active' : ''" @click="routerTo('About')">
        关于我们
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div :class="active == 'More' ? 'active' : ''" @click="linkTo('https://xiaoxxx.com/#/', 'More')">
        更多服务
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div :class="navShow ? 'nav-btn nav-active' : 'nav-btn'" @click="navShow = !navShow">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <transition name="unfold">
      <div class="mobile-nav" v-show="navShow">
        <div @click="routerTo('Home')">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202405131ea8d2d985f58c55134752.png">
          <span>首页</span>
        </div>
        <div @click="routerTo('Case')">
          <img src="https://image.xiaoxxx.cn/test/urlGather/2024051302866f31dad0a435963802.png">
          <span>精选案例</span>
        </div>
        <div @click="routerTo('News')">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202405130787f681c29dd04d767781.png">
          <span>新闻资讯</span>
        </div>
        <div @click="routerTo('About')">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240513d9a9613eb13c4721839632.png">
          <span>关于我们</span>
        </div>
        <!-- <div @click="linkTo('https://xiaoxxx.com/#/', 'More')">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202408169428f8e47592c579795986.png">
          <span>更多服务</span>
        </div> -->
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: this.$route.matched[0].name,
      navShow: false,
    }
  },
  watch: {
    $route(n) {
      this.active = n.matched[0].name
    }
  },
  methods: {
    routerTo(name) {
      this.active = name
      this.navShow = false
      this.$router.push({name: name})
    },
    linkTo(url, name) {
      this.active = name
      this.navShow = false
      window.open(url)
    }
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  min-width: var(--maxWidth);
  height: 62px;
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 11;
  background: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240205c0d6e8ee643ad27b228493.jpg') center / cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 60px;
}
.logo-img {
  height: 32px;
}
.slogan {
  /* height: 16px; */
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin-left: 15px;
  transform: rotateX(10deg) rotateY(-20deg) rotateZ(3.5deg) perspective(500px);
}
.nav-btn {
  display: none;
}
.nav {
  width: 500px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  right: 50px;
}
.nav div {
  /* width: 100px; */
  /* height: 25px; */
  font-size: 16px;
  font-weight: 400;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  /* background: linear-gradient(315deg, 
  rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 40%, #fff 60%, #fff 100%); */
  background-position: -30px 0;
  background-clip: text;
  padding: 5px 20px;
  /* color: transparent;  */
  transition: all 0.8s;
  overflow: hidden;
}
.nav span {
  display: block;
  background: #fff;
  position: absolute;
  transition: top 0.5s, left 0.5s;
  opacity: 0;
}
.nav span:nth-of-type(1) {
  width: 100px;
  height: 2px;
  top: 0;
  left: -100px;
}
.nav span:nth-of-type(2) {
  width: 2px;
  height: 25px;
  top: 25px;
  right: 0;
}
.nav span:nth-of-type(3) {
  width: 100px;
  height: 2px;
  bottom: 0;
  left: 100px;
}
.nav span:nth-of-type(4) {
  width: 2px;
  height: 25px;
  top: -25px;
  left: 0;
}
.nav .active {
  color: #fff;
  /* background: linear-gradient(315deg, 
  rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 40%, #fff 60%, #fff 100%); */
  background-clip: text;
  /* color: transparent;  */
  background-position: 30px 0;
}
.active span {
  opacity: 1;
}
.active span:nth-of-type(1) {
  left: calc(100% - 10px);
}
.active span:nth-of-type(2) {
  top: calc(-100% + 16px);
}
.active span:nth-of-type(3) {
  left: -90px;
}
.active span:nth-of-type(4) {
  top: calc(100% - 10px)
}
.mobile-nav {
  display: none;
}

@media screen and (max-width: 750px) {
  .header {
    min-width: 100vw;
    height: 12vw;
    position: fixed;
  }
  .logo {
    margin-left: 4vw;
  }
  .logo-img {
    height: 4vw;
  }
  .slogan {
    font-size: 10px;
    font-weight: 400;
    margin-left: 1.8vw;
  }
  .nav-btn {
    display: block;
    margin-right: 4vw;
  }
  .nav-btn>div {
    width: 6vw;
    height: 3px;
    background: #fff;
    margin: 5px 0;
    border-radius: 3px;
    transition: all 0.3s;
  }
  .nav-active>div:nth-of-type(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .nav-active>div:nth-of-type(2) {
    opacity: 0;
  }
  .nav-active>div:nth-of-type(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  .nav {
    display: none;
  }
  .mobile-nav {
    width: 36vw;
    /* height: 46.6vw; */
    background: rgba(0, 0, 0, 0.88);
    display: block;
    border-radius: 15px;
    padding: 5px 0;
    position: absolute;
    right: 4vw;
    top: 13.3vw;
    z-index: 9;
  }
  .mobile-nav {

  }
  .mobile-nav div {
    height: 10.6vw;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .mobile-nav img {
    width: 4vw;
    margin-right: 2.6vw;
  }
  .mobile-nav span {
    width: 24.8vw;
    height: 100%;
    line-height: 10.6vw;
    display: block;
    border-bottom: 1px solid #464649;
  }
  .mobile-nav div:last-of-type span {
    border-bottom: none;
  }
  .unfold-enter-active {
    transform-origin: center top;
    animation: unfold 0.3s;
  }
  .unfold-leave-active {
    transform-origin: center top;
    animation: unfold 0.3s reverse;
  }
  @keyframes unfold {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
}
</style>